import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import Utility from "../../../shared/utility";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      sourceLPN: "",
      ceValue: 0,
      max50Alphanumeric: [
        (v) => !!v || "Field is required",
        (v) => !v || /^[A-Z0-9-]*$/i.test(v) || "Only Alphanumberic allowed",
        (v) => (v || "").length <= 50 || "Max Length must be 50 characters or less",
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.sourceLPN = "";
      this.$refs.returnOrderConsolidationForm.resetValidation();
    },
    //Validate Source LPN
    async returnOrderConsolidation() {
      if (this.$refs.returnOrderConsolidationForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.ceValue = 1;
        let obj = {
          userid: parseInt(this.userId),
          pass: 5,
          lpn: this.sourceLPN,
          ce: parseInt(this.ceValue),
        };
        this.axios
          .post("/mm/lpn_consolidate", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.sourceLPN = "";
              this.$refs.returnOrderConsolidationForm.resetValidation();
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            this.sourceLPN = "";
            this.$refs.returnOrderConsolidationForm.resetValidation();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
